/*
 * @Description:
 * @Autor: 张健，dhq
 * @Date: 2021-06-08 13:19:25
 * @LastEditors: fengshiqiang-xphl fengshiqiang-xphl@gome.inc
 * @LastEditTime: 2024-01-10 14:50:59
 */
// import router from '@/router';
// import axios from 'axios';
// import Cookies from "js-cookie";
// import { Message, MessageBox } from 'element-ui';

// const service = axios.create({
//   //baseURL: VUE_APP_API_BASE_URL,
//   //baseURL: ,
//   timeout: 3000000
// });
// service.interceptors.request.use(
//   config => {
//     // if (Cookies.get('X-Token') || router.history.current.query.token) {
//     //   config.headers['X-Token'] = Cookies.get('X-Token') || router.history.current.query.token;
//     // }
//     return config;
//   },
//   error => {
//     Promise.reject(error);
//   }
// );

// service.interceptors.response.use(
//   res => {
//     console.log(res)
//     if (res.config.responseType === 'blob') {
//       return res;
//     }
//     if (res.data.code == 0 || res.data.code == undefined) {
//       return res.data;
//     } else {
//       console.log(res,res.data.msg,"<<<<>>>>>>>")
//       Message.error(res.data.msg);
//       return Promise.reject(res.data.msg);
//     }
//   },
//   error => {
//     console.log(error,">>>")
//     return Promise.reject(error);
//   }
// );

// export default service;


/*
 * @Description:
 * @Autor: 张健，dhq
 * @Date: 2021-06-08 13:19:25
 * @LastEditors: 张健，dhq
 * @LastEditTime: 2022-09-19 18:01:18
 */
import router from '@/router';
import axios from 'axios';
import Cookies from "js-cookie";
import { Message, MessageBox } from 'element-ui';

const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_PUBLICPATH,
  baseURL: VUE_APP_API_BASE_URL,
  timeout: 3000000
});
service.interceptors.request.use(
  config => {
    if (Cookies.get('X-Token') || router.history.current.query.token) {
      config.headers['X-Token'] = Cookies.get('X-Token') || router.history.current.query.token;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  res => {
    if (res.config.responseType === 'blob') {
      return res;
    }
    if (res.data.code == 0 || res.data.code == undefined) {
      return res.data;
    } else if (res.data.code == 403) {
      Cookies.remove('X-Token')
      router.push('/login')
      Message.error(res.data.msg);
      return Promise.reject(res.data.msg);
    } else {
      Message.error(res.data.msg);
      return Promise.reject(res.data.msg);
    }
  },
  error => {
    return Promise.reject(error);
  }
);

export default service;
