const allRoutess = [
    {
      path: '/',
      component: () => import('@/layout/index'),
      redirect: '/dataManagement/alarmManagement',
      children:[
        {
          icon: 'el-icon-s-data',
          name: '数据汇聚',
          path: '/dataManagement',
          component: () => import('@/views/applicationMonitoring/index'),
          children:[
            {
              name: '告警管理',
              path: '/dataManagement/alarmManagement',
              component: () => import('@/views/applicationMonitoring/alarmManagement'),
            }
          ]
        },
        {
          icon: 'el-icon-s-comment',
          name: '通知管理',
          path: '/noticeManagement',
          component: () => import('@/views/applicationMonitoring/index'),
          children:[
            {
              name: '短信推送',
              path: '/noticeManagement/messageManagement',
              component: () => import('@/views/applicationMonitoring/noticeManagement/messageManagement'),
            }
          ]
        },
        {
          icon: 'el-icon-s-tools',
          name: '系统管理',
          path: '/systemManagement',
          component: () => import('@/views/applicationMonitoring/index'),
          children:[
            // {
            //   name: '基础配置',
            //   path: '/systemManagement/baseManagement',
            //   component: () => import('@/views/applicationMonitoring/systemManagement'),
            // },
            {
              name: '账号管理',
              path: '/systemManagement/accountManagement',
              component: () => import('@/views/applicationMonitoring/accountManagement'),
            },
            {
              name: '角色管理',
              path: '/system/roles',
              component: () => import('@/views/system/roles'),
            }
          ]
        },
        // {
        //   icon: 'el-icon-s-tools',
        //   name: '系统管理',
        //   path: '/systemManagement',
        //   component: () => import('@/views/applicationMonitoring/index'),
        //   children:[
        //     {
        //       name: '基础配置',
        //       path: '/systemManagement/baseManagement',
        //       component: () => import('@/views/applicationMonitoring/systemManagement'),
        //     },
            
        //   ]
        // }

        {
          icon: 'el-icon-message',
          name: '短信管理',
          path: '/short',
          component: () => import('@/views/shortMessage/index'),
          children:[
            {
              name: '短信推送',
              path: '/short/message',
              component: () => import('@/views/shortMessage/management'),
            }
          ]
        },
      ]
    },
    {
      path: '/login',
      component: () => import('@/views/login'),
      hidden: true
    },
    // {
    //   name: '告警管理',
    //   path: '/alarmManagement',
    //   component: () => import('@/views/applicationMonitoring/alarmManagement'),
    // },
    // {
    //   name: '短信推送',
    //   path: '/messageManagement',
    //   component: () => import('@/views/applicationMonitoring/noticeManagement/messageManagement'),
    // }
    
]

export default allRoutess;
